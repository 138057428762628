import { EMAILREGEX,PasswordRegex} from "../config/config";


export const validateService = {
  
    register,
    login,
    passwordvalidate,
    resetpasswordvalidate,
    validateEmail,
    forgotpasswordvalidate,
    validateProfile,
    validateProduct,
    addressValidation,
    bankValidate,
    validateContactUs,
    validateBankData
    

    





}

 function register(setregisterFieldsErrs,registerFields){
 
    let formValidated = true;
       
 if (registerFields.email.trim() === "") {
    
      formValidated = false;
      setregisterFieldsErrs((obj) => {
        return {
          ...obj,
 
          email:"email_required",
          password:"",
          cpassword:"",
        
        };
      });
    } else if (!EMAILREGEX.test(registerFields.email)) {
        console.log(EMAILREGEX.test(registerFields.email),"valid email field")
      formValidated = false;
      setregisterFieldsErrs((obj) => {
        return {
          ...obj,
          email:"valid_email",
          password:"",
          cpassword:"",
        };
      });
    }else if (registerFields.password.trim() === "") {
      formValidated = false;
      setregisterFieldsErrs((obj) => {
        return {
          ...obj,
          email:"",
          password:"password_required",
          cpassword:"",
     
        };
      });
    }else if(!PasswordRegex.test(registerFields.password)){
      formValidated = false;
      setregisterFieldsErrs((obj) => {
        return {
          ...obj,
          email:"",
          password:"pass_regex",
        };
      });

    }
    else if (registerFields.confirmPassword.trim() === "") {
        formValidated = false;
        setregisterFieldsErrs((obj) => {
          return {
            ...obj,
            email:"",
            password:"",
            cpassword:"cpassword_required",
           
          };
        });
      }else if (registerFields.confirmPassword.trim() !== registerFields.password.trim()) {
        formValidated = false;
        setregisterFieldsErrs((obj) => {
          return {
            ...obj,
            email:"",
            password:"",
            cpassword:"cpassword_required",
        
          };
        });
      }else if (!registerFields.terms_and_conditions) {
        formValidated = false;
        setregisterFieldsErrs((obj) => {
          return {
            ...obj,
            terms_and_conditions: true,
            email:"",
            password:"",
            cpassword:"",
            terms_and_conditions:"term"
          };
        });
      }
      else {
        formValidated = true;
        setregisterFieldsErrs((obj) => {
          return {
            ...obj,
             email:"",
            password:"",
            cpassword:"",
            
          };
        });
      }
      return formValidated
}

function login(setloginFieldsErrs,loginFields){
       let formValidated = true;
       if (loginFields.email.trim() === "") {
           formValidated = false;
           setloginFieldsErrs((obj) => {
                return {
                ...obj,
        
                email:"email_required",
                user_typeErr:""
                };
            });
    } else if (!EMAILREGEX.test(loginFields.email)) {
        console.log(EMAILREGEX.test(loginFields.email),"valid email field")
        formValidated = false;
        setloginFieldsErrs((obj) => {
            return {
            ...obj,
            email:"valid_email",
            
            };
        });
    }else if (loginFields.password.trim() === "") {
      formValidated = false;
      setloginFieldsErrs((obj) => {
        return {
          ...obj,
    
          email:"",
          password:"password_required"
        };
      });
    }
  return formValidated
}

function passwordvalidate(setloginFieldsErrs,loginFields){
    let formValidated = true;
    if (loginFields.old_password.trim() === "") {
        formValidated = false;
        setloginFieldsErrs((obj) => {
          return {
            ...obj,
            email:"",
           password:"Enter your password"
          };
        });
      }else  if (loginFields.password.trim() === "") {
        formValidated = false;
        setloginFieldsErrs((obj) => {
          return {
            ...obj,

            password:"Enter your password"
          };
        });
      }else if (loginFields.cpassword.trim() === "") {
          formValidated = false;
          setloginFieldsErrs((obj) => {
            return {
              ...obj,
        
              emailErr:"",
              passwordErr:"",
              cpassword:"Enter your confirm password"
            };
          });
        }else if (loginFields.cpassword.trim() !== loginFields.password.trim()) {
            formValidated = false;
            setloginFieldsErrs((obj) => {
              return {
                ...obj,
               
                emailErr:"",
                password:"",
                cpassword:"Password and confirm password doesn't match"
              };
            });
        }
        return formValidated

}
function resetpasswordvalidate(setpasswordFieldsErr,passwordFields){
    let formValidated = true;
     if (passwordFields.oldPassword.trim() === "") {
        formValidated = false;
        setpasswordFieldsErr((obj) => {
          return {
            ...obj,
            oldPassword:"password_required"
          };
        });
      }else if (passwordFields.password.trim() === "") {
          formValidated = false;
          setpasswordFieldsErr((obj) => {
            return {
              ...obj,
              oldPassword:"",
              password:"password_required"
            };
          });
        }else if(!PasswordRegex.test(passwordFields.password)){
          formValidated = false;
          setpasswordFieldsErr((obj) => {
            return {
              ...obj,
              oldPassword:"",
              password:"pass_regex"
            };
          });

        }else if (passwordFields.confirmPassword.trim() === "") {
          formValidated = false;
          setpasswordFieldsErr((obj) => {
            return {
              ...obj,
               password:"",
               oldPassword:"",
              confirmPassword:"cpassword_required"
            };
          });
        }else if (passwordFields.confirmPassword.trim() !== passwordFields.password.trim()) {
            formValidated = false;
            setpasswordFieldsErr((obj) => {
              return {
                ...obj,
           
                emailErr:"",
                password:"",
                confirmPassword:"pass_and_cpass"
              };
            });
        }else  {
          formValidated = true;
          setpasswordFieldsErr((obj) => {
            return {
              ...obj,
             oldpassword:"",
              password:"",
              confirmPassword:""
            };
          });
      }
        return formValidated

}
function forgotpasswordvalidate(setpasswordFieldsErr,passwordFields){
  let formValidated = true;
  if (passwordFields.password.trim() === "") {
        formValidated = false;
        setpasswordFieldsErr((obj) => {
          return {
            ...obj,
            password:"password_required"
          };
        });
      }else if(!PasswordRegex.test(passwordFields.password)){
        formValidated = false;
        setpasswordFieldsErr((obj) => {
          return {
            ...obj,
            oldPassword:"",
            password:"pass_regex"
          };
        });

      }else if (passwordFields.confirmPassword.trim() === "") {
        formValidated = false;
        setpasswordFieldsErr((obj) => {
          return {
            ...obj,
             password:"",
             confirmPassword:"cpassword_required"
          };
        });
      }else if (passwordFields.password.trim() !== passwordFields.confirmPassword.trim()) {
          formValidated = false;
          setpasswordFieldsErr((obj) => {
            return {
              ...obj,
              password:"",
              confirmPassword:"pass_and_cpass"
            };
          });
      }else  {
        formValidated = true;
        setpasswordFieldsErr((obj) => {
          return {
            ...obj,
        
            password:"",
            confirmPassword:""
          };
        });
    }
      return formValidated

}
function validateEmail(email,setEmailErr){
  let formValidated = true
  if (email.trim() === "") {
    
    formValidated = false;
    setEmailErr("email_required")
    
  } else if (!EMAILREGEX.test(email)) {
      console.log(EMAILREGEX.test(email),"valid email field")
    formValidated = false;
    setEmailErr("valid_email")
    
  }else{
    formValidated = true;
    setEmailErr("")
  }
  return formValidated
}



function vlidateBank(setbankFieldsErrs,bankFields){
  let formValidated = true
  if (bankFields.account_holder_name.trim() === "") {
    formValidated = false;
    setbankFieldsErrs((obj) => {
     return {
       ...obj,
       account_holder_name:"Enter account holder name"
     };
   });
 } else if (bankFields.bank_id === "") {
  formValidated = false;
  setbankFieldsErrs((obj) => {
     return {
       ...obj,
       account_holder_name: "",
       bank_name:"Enter bank name"
     };
   });
 }else if (bankFields.account_number.trim() === "") {
  formValidated = false;
  setbankFieldsErrs((obj) => {
     return {
       ...obj,
       account_holder_name: "",
       bank_name:"",
       account_number:"Enter account number"
     };
   });
}
else {
  formValidated = true;
  setbankFieldsErrs((obj) => {
     return {
       ...obj,
       account_holder_name: "",
       bank_name:"",
       account_number:"",
       routing_number:""
     };
   });
}
return formValidated
}



function validateProfile(setprofileFieldsErrs,profileFields,frontFile,backFile,setfrontErr,setbackErr){

  let formValidated = true
  if (profileFields.firstName.trim() === "") {
     formValidated = false;
     setprofileFieldsErrs((obj) => {
      return {
        ...obj,
        firstName:"fname_required"
      };
    });
  }else   if (profileFields.lastName.trim() === "") {
    formValidated = false;
    setprofileFieldsErrs((obj) => {
     return {
       ...obj,
       lastName:"lname_required"
     };
   });
 }
   else if (profileFields.phone.trim() === "") {
    formValidated = false;
    setprofileFieldsErrs((obj) => {
      return {
        ...obj,
        name: "",
        phone:"phone_required"
      };
    });
  }else if ( profileFields.phone.trim() === "" || profileFields.phone.length<=3) {
    formValidated = false;
    setprofileFieldsErrs((obj) => {
      return {
        ...obj,
        name: "",
       phone:"phone_required"
      };
    });
  }else if (profileFields.businessName.trim() === "") {
    formValidated = false;
    setprofileFieldsErrs((obj) => {
      return {
        ...obj,
        name: "",
        phone:"",
        businessName:"business_required"
      };
    });
  }else if (profileFields.gender === "") {
    formValidated = false;
    setprofileFieldsErrs((obj) => {
      return {
        ...obj,
        name: "",
        phone:"",
        businessName:"",
        gender:"valid_gender"
      };
    });
  } else if (profileFields.yearOfExperience == null || profileFields.yearOfExperience == "" ) {
    formValidated = false;
    setprofileFieldsErrs((obj) => {
      return {
        ...obj,
        name: "",
        phone:"",
        businessName:"",
        gender:"",
        typeOfCultivation:"",
        yearOfExperience:"valid_exp"
      };
    });
  }
  
  
  else if (!profileFields.address || profileFields.address.trim() == "") {
    formValidated = false;
    setprofileFieldsErrs((obj) => {
      return {
        ...obj,
        name: "",
        phone:"",
        businessName:"",
        gender:"",
        yearOfExperience:"",
        typeOfCultivation:"",
        doc:"",
        location:"valid_address"
       
      };
    });
  } 
  else if (profileFields.typeOfCultivation.trim() === "") {
    formValidated = false;
    setprofileFieldsErrs((obj) => {
      return {
        ...obj,
        name: "",
        phone:"",
        businessName:"",
        gender:"",
        yearOfExperience:"",
        doc:"",
        location:"",
        typeOfCultivation:"type_of_culti"
      };
    });
  }else if (profileFields.city.trim() === "") {
    formValidated = false;
    setprofileFieldsErrs((obj) => {
      return {
        ...obj,
        name: "",
        phone:"",
        businessName:"",
        gender:"",
        yearOfExperience:"",
        location:"",
        doc:"",
        typeOfCultivation:"",
        city:"city_required"
      };
    });
  }else if (profileFields.country.trim() === "") {
    formValidated = false;
    setprofileFieldsErrs((obj) => {
      return {
        ...obj,
        name: "",
        phone:"",
        businessName:"",
        gender:"",
        yearOfExperience:"",
        location:"",
        typeOfCultivation:"",
        city:"",
        doc:"",
        country:"country_req"
      };
    });
  } else if(!frontFile){
    formValidated = false;
    setprofileFieldsErrs((obj) => {
      return {
        ...obj,
        name: "",
        phone:"",
        businessName:"",
        gender:"",
        typeOfCultivation:"",
        front:"img_valid "
      };
    });
    setfrontErr("Id_validation")
    

  }else if(!backFile){
    formValidated = false;
    setprofileFieldsErrs((obj) => {
      return {
        ...obj,
        name: "",
        phone:"",
        businessName:"",
        gender:"",
        typeOfCultivation:"",
        front:" ",
        back:"back_img-valid"
      };
    });
    setbackErr("back_valid")
    setfrontErr("")
  }
  else{
    formValidated = true;
    // setbackErr("")
    // setfrontErr("")
    setprofileFieldsErrs((obj) => {
      return {
        ...obj,
        name: "",
        phone:"",
        businessName:"",
        gender:"",
        yearOfExperience:"",
        location:"",
        typeOfCultivation:"",
        city:"",
        doc:"",
        country:""
      };

    });
  }
  return formValidated
}



function validateProduct(setproductFieldsErr,productFields,images){
  console.log("========profileFields====",productFields)
 

  let formValidated = true
  if (images.length === 0) {
    formValidated = false;
    setproductFieldsErr((obj) => {
     return {
       ...obj,
       images:"Please select atleast one image."
     };
   });

  }else if (productFields.name.trim() === "") {
     formValidated = false;
     setproductFieldsErr((obj) => {
      return {
        ...obj,
        images:"",
        name:"Enter your brand name"
      };
    });
  } else if (productFields.category === "") {
    formValidated = false;
    setproductFieldsErr((obj) => {
      return {
        ...obj,
        images:"",
        name: "",
        category:"Select category"
      };
    });
  }else if (productFields.subcategory === "") {
    formValidated = false;
    setproductFieldsErr((obj) => {
      return {
        ...obj,
        images:"",
        name: "",
        category:"",
        subcategory:"Select subcategory"
      };
    });
  }  else if (productFields.units === "") {
    formValidated = false;
    setproductFieldsErr((obj) => {
      return {
        ...obj,
        images:"",
       category:"",
        subcategory:"",
        min_order:"",
        units:"Enter your unit e.g. Box,Meter.."
      };
    });
  
  }else if (productFields.price === "" || productFields.price === 0) {
  
    formValidated = false;
    setproductFieldsErr((obj) => {
      return {
        ...obj,
        images:"",
        category:"",
        subcategory:"",
        min_order:"",
        units:"",
        price:"Price can't be empty or 0",
      };
    });
  
  }else if (productFields.min_order === "" || productFields.min_order==0) {
    formValidated = false;
    setproductFieldsErr((obj) => {
      return {
        ...obj,
        images:"",
        category:"",
        subcategory:"",
        min_order:"Min order can't be 0 or empty"
      };
    });
  
  
  }
  
 

   else if (productFields.current_stock === "") {
  
    formValidated = false;
    setproductFieldsErr((obj) => {
      return {
        ...obj,
        images:"",
        category:"",
        subcategory:"",
        min_order:"",
        units:"",
        price:"",
        weight:"",
        height:"",
        width:"",
        length:"",
        current_stock:"Current stock can't be 0 or empty"
      };
    });
  }else if (parseInt(productFields.current_stock) < parseInt(productFields.min_order)) {
  
    formValidated = false;
    setproductFieldsErr((obj) => {
      return {
        ...obj,
        images:"",
        category:"",
        subcategory:"",
        min_order:"Min order should less than current stock",
        units:"",
        price:"",
        weight:"",
        height:"",
        width:"",
        length:"",
        current_stock:""
      };
    });
  }else{
    formValidated = true;
    setproductFieldsErr((obj) => {
      return {
        ...obj,
        images:"",
        name: "",
        category: "",
        subcategory: "",
        weight: "",
        weight_unit: "",
        length: "",
        width: "",
        height: "",
        units: "",
        min_order: "",
        current_stock: "",
        add_stock: "",
        deduct_stock: "",
        price: ""
      };
    });
  }
  return formValidated
}



function addressValidation(setaddressFieldsErr,addressFields){

  let formValidated = true
  if (addressFields.first_name.trim() === "") {
     formValidated = false;
     setaddressFieldsErr((obj) => {
      return {
        ...obj,
        first_name:"Enter your first name"
      };
    });
  } else if (addressFields.last_name.trim() === "") {
    formValidated = false;
    setaddressFieldsErr((obj) => {
      return {
        ...obj,
        first_name: "",
        last_name:"Enter your last name"
      };
    });
  }else if (addressFields.address.trim() === "") {
    formValidated = false;
    setaddressFieldsErr((obj) => {
      return {
        ...obj,
        first_name: "",
        last_name:"",
        address:"Enter address"
      };
    });
  }else if (addressFields.city.trim() === "") {
    formValidated = false;
    setaddressFieldsErr((obj) => {
      return {
        ...obj,
        first_name: "",
        last_name:"",
        address:"",
        city:"Enter city name"
      };
    });
  }else if (addressFields.state.trim() === "") {
    formValidated = false;
    setaddressFieldsErr((obj) => {
      return {
        ...obj,
        first_name: "",
        last_name:"",
        address:"",
        city:"",
        state:"Enter state name"
      };
    });
  }else if (addressFields.country.trim() === "") {
    formValidated = false;
    setaddressFieldsErr((obj) => {
      return {
        ...obj,
        first_name: "",
        last_name:"",
        address:"",
        city:"",
        state:"",
        country:"Enter country name"
      };
    });
  }else{
    formValidated = true;
    setaddressFieldsErr((obj) => {
      return {
        ...obj,
        first_name: "",
        last_name:"",
        address:"",
        city:"",
        state:"",
        country:" ",
        pincode:""
      };
    });
  }
  return formValidated
}




function bankValidate(setbankFieldsErrs,bankFields){
  let formValidated = true
  if (bankFields.bank_name === "") {
    formValidated = false;
    setbankFieldsErrs((obj) => {
       return {
         ...obj,
         account_holder_name: "",
         account_holder_name: "",
         routing_number:"",
         bank_name:"bank_name_req"
       };
     });
   }
   else if (!bankFields.account_number || bankFields.account_number.trim() === "") {
    formValidated = false;
    setbankFieldsErrs((obj) => {
       return {
         ...obj,
         account_holder_name: "",
         bank_name:"",
         routing_number:"",
         account_number:"account_number"
       };
     });
  }
 else if (bankFields.account_holder_name.trim() === "") {
    formValidated = false;
    setbankFieldsErrs((obj) => {
     return {
       ...obj,
       account_number: "",
       bank_name:"",
       routing_number:"",
       account_holder_name:"acc_holder_name_req"
     };
   });
 }   else if (bankFields.routing_number.trim() === "") {
  formValidated = false;
  setbankFieldsErrs((obj) => {
   return {
     ...obj,
     account_number: "",
     bank_name:"",
     account_holder_name:"",
     routing_number:"routing_number_req"
   };
 });
}  else if (bankFields.terms_and_conditions === false) {
  formValidated = false;
  setbankFieldsErrs((obj) => {
   return {
     ...obj,
     account_number: "",
     bank_name:"",
     account_holder_name:"",
     routing_number:"",
     terms_and_conditions:"accept_agreement"
   };
 });
}  
else {
  formValidated = true;
  setbankFieldsErrs((obj) => {
     return {
       ...obj,
       account_holder_name: "",
       bank_name:"",
       account_number:"",
       routing_number:""
     };
   });
}
return formValidated
}






function validateContactUs(setcontactFieldsErrs,contactFields){

  let formValidated = true
  if (contactFields.name.trim() === "") {
    console.log("======contactFields=ss=ss====",contactFields)
     formValidated = false;
     setcontactFieldsErrs((obj) => {
      return {
        ...obj,
        name:"name_field"
      };
    });

  } else if (contactFields.phone.trim() === "") {
    console.log("======contactFields======>>",contactFields)
    formValidated = false;
    setcontactFieldsErrs((obj) => {
      return {
        ...obj,
        name: "",
        phone:"phone_field"
      };
    });
  }else if ( contactFields.phone.trim() === "" || contactFields.phone.length<=3) {
    formValidated = false;
    setcontactFieldsErrs((obj) => {
      return {
        ...obj,
        name: "",
       phone:"phone_field",
        email:"",
       message:"",
      };
    });
  }
 
 else if (contactFields.email.trim() === "") {
    
    formValidated = false;
    setcontactFieldsErrs((obj) => {
      return {
        ...obj,

        email:"email_field",
        name: "",
        phone:"",
        message:"",
      
      };
    });
  } else if (!EMAILREGEX.test(contactFields.email)) {
      console.log(EMAILREGEX.test(contactFields.email),"valid email field")
    formValidated = false;
    setcontactFieldsErrs((obj) => {
      return {
        ...obj,
        email:"valid_email",
        name: "",
        phone:"",
      
        message:"",
      
      };
    });
  }  else if (contactFields.message.trim() === "") {
   
    formValidated = false;
    setcontactFieldsErrs((obj) => {
      return {
        ...obj,
       email:"",
        name: "",
        phone:"",
       message:"message_field",
      
      };
    });
  }
  else{
    formValidated = true;
    setcontactFieldsErrs((obj) => {
      return {
        ...obj,
        name: "",
        phone:"",
        email:"",
        message:"",
       
      };
    });
  }
  return formValidated
}

function validateBankData(setbankFieldsErrs, bankFields) {
  let formValidated = true;

  if (bankFields.accountNumber.trim() === "") {
    formValidated = false;
    setbankFieldsErrs((prevErrors) => ({
      ...prevErrors,
      accountNumber: "req_accountNumber",
    }));
  }

  if (bankFields.accountType.trim() === "") {
    formValidated = false;
    setbankFieldsErrs((prevErrors) => ({
      ...prevErrors,
      accountType: "account_type_required",
    }));
  }
  
  if (bankFields.bank.trim() === "") {
      formValidated = false;
      setbankFieldsErrs((prevErrors) => ({
        ...prevErrors,
        bank: "req_bankName",
      })); 

  }

  if(bankFields.accountType === "corriente"){
    if (bankFields.nit.trim() === "") {
      formValidated = false;
      setbankFieldsErrs((prevErrors) => ({
        ...prevErrors,
        nit: "req_nit",
      }));
    }
  
    if (bankFields.rut.trim() === "") {
      formValidated = false;
      setbankFieldsErrs((prevErrors) => ({
        ...prevErrors,
        rut: "req_rut",
      }));
    }
  }

  


  

  // if (bankFields.bankCode.trim() === "") {
  //   formValidated = false;
  //   setbankFieldsErrs((prevErrors) => ({
  //     ...prevErrors,
  //     bankCode: "req_bankCode",
  //   }));
  // }

  // if (bankFields.documentNumber.trim() === "") {
  //   formValidated = false;
  //   setbankFieldsErrs((prevErrors) => ({
  //     ...prevErrors,
  //     documentNumber: "req_documentNumber",
  //   }));
  // }

  // if (bankFields.documentType.trim() === "") {
  //   formValidated = false;
  //   setbankFieldsErrs((prevErrors) => ({
  //     ...prevErrors,
  //     documentType: "req_documentType",
  //   }));
  // }

  // if (bankFields.expeditionDate.trim() === "") {
  //   formValidated = false;
  //   setbankFieldsErrs((prevErrors) => ({
  //     ...prevErrors,
  //     expeditionDate: "req_expeditionDate",
  //   }));
  // }

  // if (bankFields.fullName.trim() === "") {
  //   formValidated = false;
  //   setbankFieldsErrs((prevErrors) => ({
  //     ...prevErrors,
  //     fullName:  "req_fullName",
  //   }));
  // }

  if (bankFields.accountHolderName.trim() === "") {
    formValidated = false;
    setbankFieldsErrs((prevErrors) => ({
      ...prevErrors,
      accountHolderName:  "req_fullName",
    }));
  }

  // if (bankFields.birthDate.trim() === "") {
  //   formValidated = false;
  //   setbankFieldsErrs((prevErrors) => ({
  //     ...prevErrors,
  //     birthDate: "req_birthDate",
  //   }));
  // }

  return formValidated;
}

const validBankData1 = (setbankFieldsErrs,bankFields) => {
  let errors = {};

  // Validate accountNumber
  if (!bankFields.accountNumber.trim()) {
    errors.accountNumber = "Account number is required";
  }

  // Validate bankCode
  if (!bankFields.bankCode.trim()) {
    errors.bankCode = "Bank code is required";
  }

  // Validate documentNumber
  if (!bankFields.documentNumber.trim()) {
    errors.documentNumber = "Document number is required";
  }

  // Validate documentType
  if (!bankFields.documentType.trim()) {
    errors.documentType = "Document type is required";
  }

  // Validate expeditionDate
  if (!bankFields.expeditionDate.trim()) {
    errors.expeditionDate = "Expedition date is required";
  }

  // Validate fullName
  if (!bankFields.fullName.trim()) {
    errors.fullName = "Full name is required";
  }

  // Validate birthDate
  if (!bankFields.birthDate.trim()) {
    errors.birthDate = "Birth date is required";
  }

  setbankFieldsErrs(errors);
  // return Object.keys(errors).length === 0; // Return true if no errors
};
