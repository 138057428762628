import axios from "axios";
// import { getItem } from '../util/localStorage'
import { apiUrl } from "../config/config";
// import { duration } from "moment";
// import moment from "moment";

export const apiService = {
    login,
    signup,
    CreateProfile,
    GetProfile,
    uplodFile,
    getAllProducts,
    addProduct,
    getProducts,
    getProductDetail,
    getProductImages,
    addStock,
    changePassword,
    resetPassword,
    forgotPassword,
    GetUnitList,
    getOrders,
    getStocks,
    getStocksHistory,
    addBank,
    GetCategoryList,
    getBank,
    getOrderDetail,
    acceptRejectOrder,
    searchDriver,
    orderPickup,
    getOrdersDashboard,
    orderReview,
    orderHistory,
    dashboardCount,
    notifications,
    createPdf,
    allReviews,
    contactUs,
    stripLink,
    orderTransaction,
    online_offline,
    changeLanguage,
    addPayuBank,
    getPayuBank,
    getPayuBankData,
    accountDelete,

  
 }
const lang = localStorage.getItem("lang")

const headers =() =>
    {
        let token = localStorage.getItem("web_token")
       return {headers : { 'Authorization': `Bearer ${token}`,"role":"CULTIVATOR","Accept-Language":lang}} 
    };
    const headersLogin =() =>
    {
        let token = localStorage.getItem("web_token")
       return {headers : { "role":"CULTIVATOR","Accept-Language":lang }} 
    };

//    console.log('headers()....', headers());
async function login(data) {
    return await axios.post(`${apiUrl}/login`, data,headersLogin());
}


async function signup(data) {
  return await axios.post(`${apiUrl}/sign-up`, data,headersLogin());
}



async function CreateProfile(data,image) {
    if(image){
        let datanew = new FormData()
        datanew.append("file",image)
        await uplodFile("image",datanew)
       }
   return await axios.put(`${apiUrl}/user/cultivator/profile/`, data,headers());
 }

  async function GetProfile() {
     return await axios.get(`${apiUrl}/user/who/am/i`,headers());
  }
  async function accountDelete() {
    // console.log("====headers===",headers())
    return await axios.put(`${apiUrl}/user/delete`,{},headers());
 }
  

async function uplodFile(type,data){
    return await axios.put(`${apiUrl}/user/profile?type=${type}`, data,headers());
}

async function getAllProducts(page,size,categoryId,name){
    let params= {
      "page":page,
      "size":size,
      "categoryId":categoryId,
       "name":name
      }
    
    return await axios.post(`${apiUrl}/product/filter`, params,headers() )
  }

  async function addProduct(params){
    return await axios.post(`${apiUrl}/product/cultivator`, params,headers() )


  }
 
  async function getProducts(page,size,categoryId,name=null){
    let params= {
      "page":page,
      "size":size,
      "categoryId":categoryId,
       "name":name
      }
    
    return await axios.post(`${apiUrl}/product/cultivator/filter`, params,headers() )
  }

 async function getProductDetail(id){
  //  await axios.get(`${apiUrl}/product/file/list/${id}`,headers() )
  return await axios.get(`${apiUrl}/product/cultivator/${id}`,headers() )

 }
 async function getProductImages(id){
  return await axios.get(`${apiUrl}/product/file/list/${id}`,headers() )
 }
 async function addStock(productCultivatorId,quantity){

  return await axios.put(`${apiUrl}/product/cultivator/stock/${productCultivatorId}/quantity/${quantity}`,{},headers() )
 }
 
 async function changePassword(data){
  return await axios.post(`${apiUrl}/password/change`,data,headers() )
 }
 

 async function forgotPassword(email){
  return await axios.get(`${apiUrl}/password/forgot/`+email,headers() )
 }



 async function resetPassword(data){
  return await axios.post(`${apiUrl}/password/reset`,data,headers() )
 }

 async function getBank(){ 
return await axios.get(`${apiUrl}/bank/detail`,headers() )
 }
 
 async function GetCategoryList(){
  return await axios.get(`${apiUrl}/category/filter`,headers())
}

 async function GetUnitList(){
  return await axios.get(`${apiUrl}/unit`,headers())
}





async function getOrders(page,size,status,search){
  let params= {
    "page":page,
    "size":size,
    "status":status,
    "search":search

    }
  
  return await axios.post(`${apiUrl}/order/filter`, params,headers() )
}

async function getOrderDetail(id){
  return await axios.get(`${apiUrl}/order/${id}?commission=true`,headers() )
 }
 async function acceptRejectOrder(params){
  return await axios.put(`${apiUrl}/order/cultivator/status`,params,headers() )
 }

async function getStocks(id){
 return await axios.get(`${apiUrl}/product-stock/cultivator/${id}`,headers() )
}

async function getStocksHistory(stockid){
  return await axios.get(`${apiUrl}/product/cultivator/history/${stockid}`,headers() )
 }
 async function removeStocks(id){
  return await axios.get(`${apiUrl}/product-stock/cultivator/${id}`,headers() )
 }

 async function searchDriver(id){
  return await axios.get(`${apiUrl}/order/driver-search/${id}`,headers() )
 }
 async function orderPickup(id,otp){
  return await axios.put(`${apiUrl}/order/pickup/${id}?otp=${otp}`,{},headers() )
 }

 async function orderReview(id){
  return await axios.get(`${apiUrl}/order/review/${id}`,headers() )
 }
 async function orderHistory(id){
  return await axios.get(`${apiUrl}/order/history/${id}`,headers() )
 }
 
 async function getOrdersDashboard(page,size,status){
  let params= {
    "page":0,
    "size":10,
    "status":"ORDER_INITIATED",
    }
  
  return await axios.post(`${apiUrl}/order/filter`, params,headers() )
}
async function dashboardCount(){
  return await axios.get(`${apiUrl}/cultivator/dashboard`,headers() )
 }
 async function notifications(page,size){
  return await axios.get(`${apiUrl}/notifications/page/${page}/size/${size}`,headers() )
 }
 async function createPdf(id){
  return await axios.get(`${apiUrl}/user-order/pdf/parse/${id}`,headers() )
 }
 async function allReviews(page,size){
  return await axios.get(`${apiUrl}/product/review/page/${page}/size/${size}`,headers() )
 }
 
 async function contactUs(param){
  return await axios.post(`${apiUrl}/contact-us`,param,headers() )
 }
 async function stripLink(param){
  return await axios.get(`${apiUrl}/stripe/service-providers/generate-onboarding-link`,headers() )
 }
 async function orderTransaction(page,size,queryParam){
  return await axios.get(`${apiUrl}/user-order-transactions/page/${page}/size/${size}${queryParam}`,headers() )
 }

 async function online_offline(on_off){
  return await axios.put(`${apiUrl}/user/status/${on_off}`,{},headers() )
 }
 async function changeLanguage(lang){
  let token = localStorage.getItem("web_token")
 let language_headers= {headers : { 'Authorization': `Bearer ${token}`,"role":"CULTIVATOR","Accept-Language":lang}}
  return await axios.put(`${apiUrl}/user/language`,{},language_headers )
 }

 async function addPayuBank(param){
  return await axios.post(`${apiUrl}/payu/banks `,param,headers() )
 }
 async function getPayuBank(){
  return await axios.get(`${apiUrl}/payu/banks `,headers() )
 }
 async function getPayuBankData(){
  return await axios.get(`${apiUrl}/bank/data`,headers() )
 }

 async function addBank(param){
  return await axios.post(`${apiUrl}/bank `,param,headers() )
 }
 