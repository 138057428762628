import { Button, Col, Container, Form, Row } from "react-bootstrap";
const PaymentSuccess = () => {
  return (
    <>
      <section className="height-footer about-outer-section about-us-spanish">
        {/* <div className="about-us-section">
          <Container>
            <h2>About Us</h2>
          </Container>
        </div> */}
        {/* about us content */}
        <Container>
          <div className="paymeny-success-section">
            <img
              className="logo"
              src={require("../assets/images/tick_icon.png")}
              alt="img"
            />
             {/* <img
              className="logo"
              src={require("../assets/images/failed_icon.png")}
              alt="img"
            /> */}
            <h3>Payment successfull! </h3>
            <p>Thank you for your order.</p>
          </div>
        </Container>

      </section>
    </>
  );
};
export default PaymentSuccess;
;
